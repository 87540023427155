.comparison {
    &__main-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__thumbnails--mobile {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            flex: 1;
            height: 100%;
            margin-top: 10px;
        }
    }

    .title {
        height: 41px;
        color: var(--font-light);
        font-weight: 300;
        display: flex;
        align-items: center;

        &--mobile {
            min-height: 37px;
            height: auto;
        }
    }

    .calendar {
        &--b {
            flex: 0;
        }
    }

    .__rcs-handle-root {
        color: var(--primary) !important;
    }
}
