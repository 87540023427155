$background: #23272c;
$disabled: #5d6771;
$height: 38px;
$border-radius: 4px;
$white: #ffffff;

.input-calendar {
    display: flex;
    font-family: inherit;
    color: inherit;
    border-radius: $border-radius;

    .react-calendar {
        height: 100%;
        background: $background;
        border: none !important;

        &__navigation {
            margin-bottom: 0;

            button {
                &:enabled {
                    &:hover,
                    &:focus {
                        border: none !important;
                    }
                }
            }
        }

        &__tile {
            line-height: 12px;

            &--active {
                background: transparent !important;
                border: 1px solid var(--primary) !important;
            }
        }

        &__month-view {
            &__weekdays {
                font-size: 0.70em;
                color: var(--font-light)
            }
        }

        button {
            background: transparent;
            border: 1px solid transparent;
            color: white;
            min-width: auto;

            &:enabled {
                &:hover,
                &:focus {
                    cursor: pointer;
                    background: inherit;
                    border: 1px solid var(--primary);
                }
            }

            &:disabled {
                color: $disabled;
            }
        }
    }

    .react-date-picker {
        width: 100%;

        i {
            padding: 10px;
        }

        &__calendar {
            z-index: 2;
        }

        &__wrapper {
            height: $height;
            background: white;
            border: 1px solid #d8d8d8;
            border-radius: 2px;
            justify-content: center;
            border-radius: $border-radius;
            overflow: hidden;

            .react-date-picker__button {
                height: $height;
                padding: 0;
                flex: 1;
            }
        }

        &__inputGroup {
            display: none;
        }
    }

    .icon-dropdown {
        display: flex;
        align-items: center;
        width: 100%;

        &__text {
            font-size: 15px;
            padding: 0px 10px;
            text-align: left;
            flex: 1;
            color: hsl(0, 0%, 20%);
            font-weight: 390;
        }
    }

    abbr {
        text-decoration: none;
        font-size: 0.8em;
    }
}

.bi-calendar-event {
    color: hsl(0, 0%, 60%);
}

.react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    z-index: 1000;
}

.react-date-picker__button {
    width: 100%;
    background-color: #ffffff;
    border: 0px solid #ffffff;
}

.react-date-picker__button:hover {
    background-color: #ffffff;
    border: 0px solid #ffffff;
}

.input-calendar--mobile {
    flex: 0;
    flex-basis: 100px;
    border-radius: 10px;

    .react-date-picker {
        height: $height;

        .react-date-picker__wrapper {
            height: $height;

            .icon-dropdown {
                height: $height;
            }
        }
    }
}

.input-calendar-mobile-custom {
    background-color: #ffffff;
    padding: 8.5px 4px;
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 80%);

    i {
        margin-right: 8px;
    }

    &__board {
        background-color: $background;
        border-radius: 0px;
        border: 0px solid transparent;
    }

    &__popper {
        z-index: 1000 !important;
    }
}

.react-datepicker {
    &__day-name {
        padding: 2px 8px;
        color: $white;
        font-size: 11px;
    }

    &__day {
        color: $white !important;
        padding: 0px 6px;
        border: 1px solid transparent;
        font-weight: normal;
        font-size: 11px;
    }

    &__day--disabled {
        color: $disabled !important;
        padding: 2px 6px;
        border: 1px solid transparent;
        font-weight: normal;
    }

    &__day--keyboard-selected {
        background-color: transparent;
    }

    &__header {
        text-align: center;
        background-color: $background;
        border-bottom: 1px solid #aeaeae;
        border-top-left-radius: 0.3rem;
        padding: 12px 0px;
        position: relative;
    }

    &__current-month {
        color: $white;
    }

    &__day--selected {
        background-color: transparent;
        border: 1px solid var(--primary);
        border-radius: 0px;
    }

    &__day:hover {
        background-color: transparent;
        border: 1px solid var(--primary);
        border-radius: 0px;
    }
}