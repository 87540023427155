.thumbnail {
    display: flex;
    justify-content: center;
    position: relative;

    input[type='checkbox'] {
        position: absolute;
        top: 0;
        right: 0;
        height: 1.8rem;
        width: 1.8rem;
        margin-top: 3%;
        margin-right: 4%;
        accent-color: var(--primary);
        cursor: pointer;
    }

    img {
        display: block;
        width: 100%;
        height: fit-content;
        object-fit: contain;
        border: 2px solid transparent;
        cursor: pointer;
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 10px;
        position: absolute;
        bottom: 0;
        transform: translateY(-15px);
        height: 2rem;
        background-color: black;
        opacity: 0.8;
        pointer-events: none;

        p {
            color: var(--font-light);
            margin: 0;
        }
    }

    .active {
        border: 2px solid var(--primary);
    }
}
