@import 'swiper/css';

:root {
    --primary: #eb9000;
    --secondary: #737374;
    --nav: #000;
    --error: red;
    --darker: #353535;
    --dark: #515151;
    --gray: #949494;
    --light-gray: #d7d7d7;
    --font-light: white;
    --bg-dark: #181818
}

html,
body,
.content,
#root {
    height: 100%;
    background: var(--nav);
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

body {
    padding: 0;
    margin: 0;
    background-color: grey;
    overflow-y: hidden;
}

#root {
    display: flex;
    flex-direction: column;
}

h1,
h2,
h3,
h4 {
    margin: 0;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--primary);
}

.zIndex-4 {
    z-index: 4;
}