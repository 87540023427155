.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--nav);
    padding: 10px 20px;

    &__logo {
        object-fit: contain;
        height: 3.0rem;
        width: 100%;
    }

    &__right {
        display: flex;
        align-items: center;
        margin-left: auto;

        .camera-dropdown {
            width: min(40vw, 300px);
            margin-right: 20px;
            z-index: 5;
        }

        .profile-dropdown {
            display: inline-block;
            position: relative;
            cursor: pointer;

            &__image {
                color: var(--font-light);
                font-size: 1.8rem;
            }

            &__content {
                display: none;
                position: absolute;
                background: var(--font-light);
                width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 6;
                right: 0;

                div {
                    display: block;
                    padding: 10px;
                    text-decoration: none;
                }

                div:hover {
                    background: var(--primary);
                }
            }
        }

        .profile-dropdown:hover .profile-dropdown__content {
            display: block;
        }
    }
}
