.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    gap: 2.3em;
    width: 300px;
    color: var(--font-light);
    height: 90%;

    img {
        width: 6em;
        height: auto;
    }

    h3 {
        border-bottom: 2px solid var(--primary);
        padding-bottom: 10px;
        margin-bottom: 15px;
        font-weight: 200;
        cursor: default;
    }

    Form {
        display: contents;
    }

    label {
        font-size: 0.7rem;
    }

    label[for='remember'] {
        display: flex;
        align-items: center;
        gap: 10px;
        width: fit-content;
        cursor: pointer;
    }

    input[type='text'],
    input[type='email'],
    input[type='password'] {
        background: transparent;
        appearance: none;
        border: 0;
        border-bottom: 2px solid var(--secondary);
        color: var(--font-light);
        margin-top: 5px;
        padding: 10px 1px;
    }

    input[type='email']:focus,
    input[type='password']:focus {
        outline: none;
        border-bottom: 2px solid var(--primary);
    }

    input[type='checkbox'] {
        height: 1rem;
        width: 1rem;
        accent-color: var(--primary);
        cursor: pointer;
    }

    .errorMsg {
        color: var(--error);
    }

    .input-container {
        display: grid;

        h5 {
            color: var(--gray);
            margin: 0px 0px 5px 0px;
        }
    }
}
