.reactdropdown {
    width: 100%;
    color: var(--nav);
    height: 38px;

    .Dropdown {
        &-control {
            cursor: pointer;
        }

        &-arrow {
            top: 15px;
        }

        &-menu {
            z-index: 2;
        }

        &-option:hover {
            background: var(--primary);
        }

        &-placeholder {
            white-space: nowrap;
        }
    }

    &__option-list {
        z-index: 2 !important;
    }
}
