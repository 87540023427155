.imageviewer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;

    &--fullscreen {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99;
    }

    &__fullscreen-icon {
        top: 16px;
        left: 16px;
        color: var(--primary);
        font-size: 4em;
        position: absolute;
        cursor: pointer;
        z-index: 1;
        width: 38px;
        height: 38px;

        &--active {
            z-index: 100;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        align-items: center;
    }
}
