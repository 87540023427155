.videos {
    overflow: hidden;

    &__lsc {
        display: flex;
        flex-direction: column;
        flex: 1;

        a {
            display: block;
            text-decoration: none;
            color: lightgray;
            font-size: 1.1rem;
            padding: 20px 10px;
        }

        a:not(.active):hover {
            text-decoration: none;
            color: var(--font-light);
        }

        .active {
            color: var(--primary);
            pointer-events: none;
        }
    }

    &__player {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        height: 100%;
        gap: 10%;
    }

    &__thumbnails {
        display: grid;
        grid-gap: 10px;
        width: 100%;

        &.col-2 {
            grid-template-columns: 1fr 1fr;
        }

        &.col-3 {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &__rsc {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-end;
        gap: 10px;

        &__back {
            background: var(--secondary);
        }
    }

    .video-dropdown {
        width: 120px;
    }
}

.empty-thumbnails {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 2rem;
    color: var(--font-light);
}
