.images {
    &__content {
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        gap: 10px;
        height: 100%;
        width: 100%;
    }

    &__rsc {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 5px 10px 0 10px;
        height: 100%;

        &__toggle {
            justify-content: space-between;
        }

        &__download {
            margin-top: auto;
        }
    }

    .calendar {
        &--mobile {
            width: 120px;
        }
    }
}
