.input-toggle {
    display: flex;
    flex-direction: column;

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        /* The switch - the box around the slider */
        &__switch {
            position: relative;
            display: inline-block;
            width: 50px;
            height: 24px;

            /* The slider */
            &__slider {
                border-radius: 25px;
                background: var(--secondary);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transition: 0.4s;
                -webkit-transition: 0.4s;
                cursor: pointer;

                /* Rounded sliders */
                &:before {
                    border-radius: 50%;
                    background: white;
                    height: 26px;
                    width: 26px;
                    content: '';
                    position: absolute;
                    bottom: -1px;
                    transition: 0.4s;
                    -webkit-transition: 0.4s;
                }
            }
        }
    }

    &__subtitle {
        font-size: 0.8em;
        white-space: wrap;
        margin-top: 20px;
    }

    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked + .input-toggle__toggle__switch__slider {
        background: var(--primary);
    }

    input:checked + .input-toggle__toggle__switch__slider:before {
        transform: translateX(26px);
        -ms-transform: translateX(26px);
        -webkit-transform: translateX(26px);
    }
}
